import React from 'react';
import img1 from "../assets/images/icon1.png"
import img2 from "../assets/images/icon2.png"
import img3 from "../assets/images/icon3.png"

const Features=()=> {
  const features = [
    {
      icon: img1,
      title: "Top-Notch Quality",
      description: "Our games feature stunning graphics, dynamic soundtracks, and innovative gameplay mechanics, making every spin an adventure."
    },
    {
      icon: img2,
      title: "Safe & Secure",
      description: "Your safety is our priority. We provide a secure environment where you can play with complete peace of mind."
    },
    {
      icon: img3,
      title: "Community & Support",
      description: "Join a vibrant community of fellow players and enjoy responsive customer support whenever you need it."
    }
  ];

  return (
    <div className="section sec-features features">
      <div className="container">
        <div className="features__inner">
          <div className="sct-header center">
            <h2 className="heading">Why play with us</h2>
            <p>At Social Games Clubs, we offer a gaming experience that's second to none. Here's why you should choose us:</p>
          </div>
          <div className="features__list">
            {features.map((feature, index) => (
              <div key={index} className="feature">
                <div className="feature__icon">
                  <img src={feature.icon} alt={feature.title} className="lazy" />
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;