import React from 'react';
import Navigation from '../components/Navigation';
import CookieRegular from '../components/CookieRegular';

const Cookie=()=> {
  return (
    <div className="about-page">
      <Navigation />
     
      <CookieRegular/>
      
    </div>
  );
}

export default Cookie;