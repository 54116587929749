import React from 'react';
import Navigation from '../components/Navigation';
import ContactForm from '../components/ContactForm';

const Contact=()=> {
  return (
    <div className="about-page">
      <Navigation />
      <ContactForm/>
      
    </div>
  );
}

export default Contact;