import React from 'react';
import Navigation from '../components/Navigation';
import Regular from '../components/Regular';
import Features from '../components/Features';
import Banner from '../components/Banner';
import BannerP from '../components/BannerP';
import Reviews from '../components/Reviews';

const About=()=> {
  return (
    <div className="about-page">
      <Navigation />
      <Regular/>
      <Features/>
      <Banner/>
      <BannerP />
      <Reviews />
    </div>
  );
}

export default About;