import React from 'react';

const Hero=()=> {

  return (
    <div className="section--hero hero">
      <div className="container">
        <div className="hero__inner">
          <span className="pre-heading">Welcome to</span>
          <h1 className="hero-title">Social Games Clubs</h1>
          <p className="hero-text">
            Welcome to Social Games Clubs, the ultimate hub for thrilling, free-to-play casino games inspired by the world of superheroes! Dive into an exciting gaming experience where every spin brings you closer to epic adventures.
          </p>
          <a href="#games" className="button">Play Now</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;