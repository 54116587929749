import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // İkonları import et

const Reviews = () => {
  const [currentReview, setCurrentReview] = useState(0);
  const reviews = [
    {
      image: "user3.jpg",
      title: '"Endless Fun"',
      content: "I've been playing on Social Games Clubs for a few months now, and I can't get enough! The games are so much fun, and I love that I don't have to spend any money to enjoy them. The superhero themes are awesome, and the graphics are top-notch. Highly recommend!",
      name: "Henry Cole"
    },
    {
      image: "Frame-2024-08-13T165145.149.jpg",
      title: '"Best Free Slot Game"',
      content: "Social Games Clubs is hands down the best site for free slot games. The variety of games keeps things interesting, and the ability to play on my phone or laptop is super convenient. The customer support team is also really helpful whenever I have questions. A fantastic experience all around!",
      name: "James Ramesh"
    },
    {
      image: "Frame-2024-08-13T165208.553.jpg",
      title: '"Super Fun"',
      content: "What I love most about Social Games Clubs is that there's no pressure to spend money. I can play as much as I want, and it's all just for fun. The games are really well-designed, and I always find myself coming back for more. It's my go-to site when I need to unwind.",
      name: "Emily Wilnington"
    },
    {
      image: "Frame-2024-08-13T165235.815-e1723565256662.jpg",
      title: '"Great Games"',
      content: "Social Games Clubs has become my favorite way to relax after a long day. The games are exciting, and the site is easy to navigate. I appreciate that everything is free, and I can play on any device without any issues. Definitely a five-star experience!",
      name: "Mike Shaikh"
    }
  ];

  const plusDivs = (n) => {
    setCurrentReview(prevReview => {
      let newReview = (prevReview + n) % reviews.length;
      if (newReview < 0) newReview = reviews.length - 1;
      return newReview;
    });
  };

  return (
    <div className="section sec-reviews">
      <div className="container">
        <div className="reviews">
          {reviews && reviews.length > 0 ? (
            reviews.map((review, index) => (
              <div key={index} className="review" style={{ display: index === currentReview ? 'flex' : 'none' }}>
                <div className="review__image">
                  {review.image ? <img src={review.image} alt="Review" className="lazy" /> : null}
                </div>
                {review.title && <h3>{review.title}</h3>}
                {review.content && <p>{review.content}</p>}
                <div className="review__row">
                  {review.name && <div className="name">{review.name}</div>}
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="star"></i>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No reviews available</p>
          )}
        </div>
        <div className="arrow-btn">
          <button className="btn display-left" onClick={() => plusDivs(-1)}>
            <FaArrowLeft /> {/* Sol ok ikonu */}
          </button>
          <button className="btn display-right" onClick={() => plusDivs(1)}>
            <FaArrowRight /> {/* Sağ ok ikonu */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
