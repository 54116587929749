import React from 'react';

const CookieRegular = () => {


    return (
        <div className="section section-regular">
            <div className="container">
                <div className="content">
                    <p>Welcome to Social Games Clubs! Your privacy is our priority, and we are committed to safeguarding your personal information while you enjoy our free games. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our website.</p>
                    <p><strong>Information Collection</strong></p>
                    <p>Social Games Clubs collects information to enhance our services and provide you with the best possible experience. The types of information we collect include:</p>
                    <p>– Personal Information: While using our site, you may be asked to provide certain personally identifiable information, such as your name and email address, which can be used to contact or identify you.<br/>
                        – Usage Data: We collect data on how our service is accessed and utilized. This may include details like your computer’s Internet Protocol (IP) address, browser type, browser version, the pages you visit on our site, the time and date of your visit, time spent on those pages, unique device identifiers, and other diagnostic data.</p>
                    <p><strong>Cookies and Tracking Technologies</strong></p>
                    <p>We use cookies and similar tracking technologies to monitor activity on our service and retain certain information. Cookies are small data files that may include an anonymous unique identifier. They are sent to your browser from a website and stored on your device. In addition to cookies, we use beacons, tags, and scripts to collect and track information to improve and analyze our service.</p>
                    <p><strong>Use of Data</strong></p>
                    <p>The information we collect is used for various purposes, including:</p>
                    <p>– To provide and maintain our service<br />
                        – To notify you about changes to our service<br />
                        – To enable you to participate in interactive features of our service at your request<br />
                        – To provide customer support<br />
                        – To gather valuable insights and analysis to improve our service<br />
                        – To monitor and analyze the usage of our service<br />
                        – To detect, prevent, and resolve technical issues</p>
                    <p><strong>Managing Cookies</strong></p>
                    <p>You have the option to instruct your browser to refuse all cookies or to notify you when a cookie is being sent. However, please note that if you do not accept cookies, some parts of our service may become inaccessible.</p>
                    <p><strong>Data Protection</strong></p>
                    <p>We prioritize the security of your data, but it’s important to understand that no method of transmission over the Internet or electronic storage is completely secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee absolute security.</p>
                    <p><strong>Changes to This Privacy Policy</strong></p>
                    <p>Our Privacy Policy may be updated periodically. We will inform you of any changes by posting the updated policy on this page.</p>
                    <p><strong>Contact Us</strong></p>
                    <p>If you have any questions or concerns regarding this Privacy Policy, please reach out to us through our ‘Contact Us’ page.</p>
                    <p>Thank you for choosing Social Games Clubs. We are committed to ensuring that your experience is both enjoyable and secure!</p>
                </div>
            </div>
        </div>
    );
}

export default CookieRegular;