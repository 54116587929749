import React, { useState, useEffect } from 'react';

const Games = () => {
  const trendingGames = [
    
    {
      id: 2,
      name: "Tic Tac Take",
      image: "vs10tictac.png",
      link: "vs10tictac#sct-game"
    },
    {
      id: 3,
      name: "Disco Lady",
      image: "vs243discolady.png",
      link: "vs243discolady#sct-game"
    },
    {
      id: 4,
      name: "Might of Ra",
      image: "vs50mightra.png",
      link: "vs50mightra#sct-game"
    },
    {
      id: 5,
      name: "Clover Gold",
      image: "vs20mustanggld2.png",
      link: "vs20mustanggld2#sct-game"
    },
    {
      id: 6,
      name: "Extra Juicy Megaways",
      image: "vswaysxjuicy.png",
      link: "vswaysxjuicy#sct-game"
    },
    {
      id: 7,
      name: "The Hand of Midas",
      image: "vs20midas.png",
      link: "vs20midas#sct-game"
    },
    {
      id: 8,
      name: "888 Dragons",
      image: "vs1dragon8.png",
      link: "vs1dragon8#sct-game"
    },
    {
      id: 9,
      name: "Fruit Party",
      image: "vs20fruitparty.png",
      link: "vs20fruitparty#sct-game"
    },
    {
      id: 10,
      name: "Aztec Gems",
      image: "vs5aztecgems.png",
      link: "vs5aztecgems#sct-game"
    }
  ];

  const [game, setGame] = useState(null);

  // Scroll to iframe container when a game is clicked
  useEffect(() => {
    if (game) {
      const gameArea = document.getElementById('gamerarea');
      if (gameArea) {
        gameArea.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [game]);

  return (
    <div id="games" className="section section--games">
     
    { game &&  (

<div id="gamerarea" className="gamerarea" style={{
  width: "100%",
  height: "600px",
  position: "relative",  
  overflow: "hidden",     
  border: "1px solid #ccc" 
}}>
<iframe
  className="game-iframe"
  tabIndex="0"
  frameBorder="0"
  scrolling="no"
  allowFullScreen
  src={`https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=${game}`}
  style={{
      width: "100%",
      height: "100%",
      border: "none"
  }}
></iframe>
</div>
    )
      
    }



   

      <div className="container">
        <div className="games-section-cols">
          <div className="games-list-inner">
            <div className="sct-header">
              <h2 className="heading">TRENDING GAMES</h2>
              <p>
                Whether you enjoy epic battles, high-energy action, or fantastical worlds, these games are sure to
                keep you engaged. Experience the excitement and start playing our trending games today!
              </p>
            </div>
            <div className="games-grid">
              {trendingGames.map((game) => (
                <div key={game.id} className="single-game">
                  <div className="single-game-image-wrapper">
                    <a onClick={e => setGame(game.link)}>
                      <img
                        className="lazy entered loaded"
                        src={game.image}
                        alt={game.name}
                        data-ll-status="loaded"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
