import React from 'react';

const BannerP=()=> {
  return (
    <div className="section sec-bannerp bannerp">
        <div className="container">
            <div className="bannerp__inner">
                <div className="bannerp__image">
                    <img width="651" height="336" src=" f-img.png"
                        className="lazy" alt="" decoding="async"
                        srcset="f-img.png 651w, f-img-300x155.png 300w"
                        sizes="(max-width: 651px) 100vw, 651px"/>
                </div>
                <div className="sct-header">
                    <h2 className="heading">
                        play anywhere <span>on any device</span> </h2>
                    <p>With Social Games Clubs, the excitement is always at your fingertips! Our platform is fully optimized
                        for all devices, allowing you to play your favorite slot games anytime, anywhere. Whether you’re
                        at home on your computer, commuting with your smartphone, or relaxing with your tablet.</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default BannerP;