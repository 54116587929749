import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import GamesPage from './pages/Games';
import About from './pages/About';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import "./App.css"
import Hero from './components/Hero';
import Contact from './pages/Contact';
import Cookie from './pages/Cookie';
import Terms from './pages/Terms';

const App=()=> {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Hero/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Cookie />} />
          <Route path="/terms" element={<Terms />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;