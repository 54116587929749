import React from 'react';
import img1 from "../assets/images/npn-img.png"
const Banner=()=> {
  return (
    <div className="section sec-banner banner">
      <div className="container">
        <div className="banner__inner">
          <div className="sct-header">
            <h2 className="heading">NO PURCHASE <span>NECESSARY</span></h2>
            <p>At Social Games Clubs, all the fun comes with no strings attached! You can enjoy our entire selection of slot games without ever needing to make a purchase. Every game is completely free-to-play, ensuring that you experience all the excitement without spending a single cent.</p>
            <a href="#games" className="button">Play Now</a>
          </div>
          <div className="banner__image">
            <img src={img1} alt="No Purchase Necessary" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;