
import React from 'react';
import Games from '../components/Games';
import Features from '../components/Features';
import Banner from '../components/Banner';
import Reviews from '../components/Reviews';
import BannerP from '../components/BannerP';

const GamesPage=()=> {
  return (
    <div className="games-page">
      <Games />
      <Features/>
      <Banner/>
      <BannerP />
      <Reviews />
    </div>
  );
}

export default GamesPage;