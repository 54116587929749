
import React from 'react';  

const ContactForm = () => {
    return (
        <section className="sct-form section">
            <div className="form-inner">
                <div className="container">
                    <div className="form-wrapper">
                        <form action="" method="POST" className="form form--contact" id="contact-form">
                            <input type="hidden" name="action" value="process_my_form" />
                            <div className="form-row">
                                <input className="input" type="text" placeholder="Name" name="name" />
                            </div>
                            <div className="form-row">
                                <input required="" className="input" type="email" placeholder="Email" name="email" />
                            </div>
                            <div className="form-row">
                                <textarea required="" className="textarea" type="text" placeholder="Message" name="message"></textarea>
                            </div>
                            <div className="form-row">
                                <button type="submit" className="button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;