import React from 'react';
import { Link } from 'react-router-dom';
import img1 from "../assets/images/social_games_clubs_logo.jpg"
const Navigation=()=> {
  return (
    <nav className="navigation">
      <div className="container">
        <div className="navigation-inner">
          <Link to="/" className="site-logo">
            <img src={img1} className='logos' alt="Social Games Clubs logo" />
          </Link>
          <ul className="nav-menu">
            <li><Link to="/games">All Games</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;