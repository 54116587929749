import React from 'react';
import { Link } from 'react-router-dom';
import immg1 from "../assets/images/social_games_clubs_logo.jpg"
const Footer=()=> {
  return (
    <div className="footer">
      <div className="container">
        <img className="footer-site-logo logos" src={immg1} alt="Social Games Clubs logo" />
        <div className="footer-text">
          <p>Social Games Clubs PROVIDES ITS USERS WITH AN AMAZING SELECTION OF FREE-TO-PLAY SLOTS, REELS, AND OTHER ONLINE SOCIAL GAMES. THIS WEBSITE DOES NOT REQUIRE ANY REAL MONEY PURCHASES AND DOES NOT ALLOW USERS TO WIN ANY REAL PRIZES. THIS WEBSITE IS INTENDED SOLELY FOR ENTERTAINMENT PURPOSES AND SHOULD BE USED AS SUCH.</p>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="footer-body">
          <div className="footer-nav">
            <ul className="footer-menu">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/games">All Games</Link></li>
              <li><Link to="/privacy">Cookies & Privacy Policy</Link></li>
              <li><Link to="/terms">Terms & Conditions</Link></li>
            </ul>
          </div>
        </div>
        {/* Footer logos and other elements */}
      </div>
    </div>
  );
}

export default Footer;