import React from 'react';

const TermsRegular = () => {


  return (
    <div className="section section-regular">
      <div className="container">
        <div className="content">
          <p>Welcome to Social Games Clubs! By accessing and using our website and services, you agree to adhere to and be governed by the following terms and conditions. Please review them carefully.</p>
          <p><strong>Acceptance of Terms</strong></p>
          <p>Your use of Social Games Clubs services signifies full acceptance of these terms. If you do not agree with any part of these terms, you must not use our website or services.</p>
          <p><strong>Eligibility</strong></p>
          <p>Our services are intended exclusively for users of legal age or older. By using our services, you confirm that you are of legal age to enter into a binding agreement with Social Games Clubs.</p>
          <p><strong>Account Registration</strong></p>
          <p>To access certain features of our site, you may need to register an account. You are responsible for keeping your account information confidential and for all activities that occur under your account. Social Games Clubs reserves the right to terminate accounts, refuse service, or modify content at its sole discretion.</p>
          <p><strong>Free-to-Play</strong></p>
          <p>All games offered by Social Games Clubs are free to play, with no real money required or involved. Our games are designed purely for entertainment and do not offer “real money gambling” or opportunities to win real money or prizes.</p>
          <p><strong>Intellectual Property Rights</strong></p>
          <p>The content, games, and other materials on our website are the exclusive property of Social Games Clubs or its licensors. You may not use, copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any content for any other purposes without prior written consent from Social Games Clubs.</p>
          <p><strong>User Conduct</strong></p>
          <p>You agree to use our website only for lawful purposes. You are prohibited from using our site to transmit or send unsolicited commercial communications. You may not use our site’s content for any marketing-related purpose without our express written consent.</p>
          <p><strong>Limitation of Liability</strong></p>
          <p>Social Games Clubs will not be liable for any damages or injuries resulting from your use of our site. This includes, but is not limited to, damages or injuries caused by the use of (or inability to use) the site or any other site to which you may link from our site.</p>
          <p><strong>Changes to Terms</strong></p>
          <p>Social Games Clubs reserves the right, at its sole discretion, to modify or replace these terms at any time. If a significant revision is made, we will provide at least 30 days’ notice before the new terms take effect. What constitutes a significant change will be determined at our sole discretion.</p>
          <p><strong>Contact Us</strong></p>
          <p>If you have any questions about these terms, please reach out to us via our ‘Contact Us’ page.</p>
          <p>Thank you for choosing Social Games Clubs. We hope you enjoy our games and have an amazing experience on our site!</p>
        </div>
      </div>
    </div>
  );
}

export default TermsRegular;
